import { SelectedVariants } from '@wix/bookings-uou-types';
import { PriceUtils } from '@wix/bookings-uou-mappers';
import { SlotService } from '../../../state/types';
import { TFunction } from '../../../../types/types';

export const getPriceLineText = ({
  slotService,
  dateRegionalSettingsLocale,
  t,
  selectedVariant,
  totalNumberOfParticipants = 1,
}: {
  slotService: SlotService;
  dateRegionalSettingsLocale: string;
  t: TFunction;
  selectedVariant?: SelectedVariants;
  totalNumberOfParticipants?: number;
}) => {
  const { paymentDetails } = slotService;
  let priceText = '';

  if (selectedVariant) {
    const price =
      slotService.dynamicPriceInfo?.serviceOptionsAndVariants?.variants?.values?.find(
        (value) =>
          value.choices?.find(
            (choice) =>
              (choice.custom &&
                choice.custom === selectedVariant?.choices?.[0].custom) ||
              (choice.staffMemberId &&
                choice.staffMemberId ===
                  selectedVariant?.choices?.[0].staffMemberId),
          ),
      )?.price;

    priceText = `${PriceUtils.getFormattedCurrency({
      price: Number(price?.value),
      currency: price?.currency!,
      locale: dateRegionalSettingsLocale,
    })}${
      selectedVariant?.choices?.[0].custom
        ? ` (${selectedVariant?.choices?.[0].custom})`
        : ''
    }${
      totalNumberOfParticipants > 1
        ? ' ' +
          t(
            'app.bookings-form.service-details.dynamic-price.number-of-participants',
            { numberOfParticipants: selectedVariant?.numberOfParticipants },
          )
        : ''
    }`;
  } else {
    const price = PriceUtils.getFormattedCurrency({
      price: paymentDetails.minPrice?.price!,
      currency: paymentDetails.currency,
      locale: dateRegionalSettingsLocale,
    });

    if (
      paymentDetails.isVariedPricing ||
      Number(paymentDetails.minPrice?.price)
    ) {
      priceText =
        paymentDetails.isVariedPricing &&
        !slotService?.dynamicPriceInfo?.selectedPreferences?.length
          ? t('app.payment.from-price.text', { price })
          : price;

      if (totalNumberOfParticipants > 1) {
        priceText = `${priceText} ${t(
          'app.bookings-form.service-details.dynamic-price.number-of-participants',
          { numberOfParticipants: totalNumberOfParticipants },
        )}`;
      }
    }
  }

  if (paymentDetails.minCharge) {
    priceText = `${priceText} (${PriceUtils.getFormattedCurrency({
      price: paymentDetails.minCharge,
      currency: paymentDetails.currency,
      locale: dateRegionalSettingsLocale,
    })} ${t('app.payment.summary.deposit.text')})`;
  }

  return priceText;
};
