import React, { useMemo } from 'react';
import { ReservedPaymentOptionIds } from '../../../../types/types';
import { getServiceSlotIdentifier, mapToArray } from '../../../../utils';
import { ServiceData, SlotService } from '../../../../utils/state/types';
import { CustomOption } from '../../../../types/dynamicPrice';
import { getMaxNumberOfParticipants } from '../../../../utils/numberOfParticipant';
import { FormStatus } from '../../../../types/form-state';
import { useFormActions } from '../../Hooks/useFormActions';
import PriceOptionDropdown from './PriceOptionDropdown/PriceOptionDropdown';
import { DynamicPriceErrorType, FormError } from '../../../../types/errors';
import { getErrorByType } from '../../../../utils/errors/errors';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { ExperimentsConsts } from '../../../../consts/experiments';
import { DateTimeFormatter } from '@wix/bookings-date-time';

export interface PriceOptionDropdownContainerProps {
  serviceData: ServiceData;
  status: FormStatus;
  errors: FormError[];
  dateRegionalSettingsLocale: string;
}

export interface PriceOptionNumberOfParticipants {
  choiceId: string;
  title: string;
  numberOfParticipants: number;
}

const PriceOptionDropdownContainer: React.FC<
  PriceOptionDropdownContainerProps
> = ({ serviceData, status, errors, dateRegionalSettingsLocale }) => {
  const { t } = useTranslation();
  const { onSelectedVariants } = useFormActions();
  const { experiments } = useExperiments();
  const actions = useFormActions();

  const dateAndTimeFormatter = useMemo(
    () => new DateTimeFormatter(dateRegionalSettingsLocale),
    [dateRegionalSettingsLocale],
  );

  const servicesWithDynamicPriceWithCustomOptions = mapToArray<SlotService>(
    serviceData.slotServices,
  ).filter(
    (slotService) =>
      slotService.dynamicPriceInfo?.customOptions &&
      !slotService.dynamicPriceInfo?.nonEditable,
  );

  const { clearErrorByTypes } = actions;
  const firstDynamicPriceError = getErrorByType({
    errorType: DynamicPriceErrorType,
    errors,
  });

  const getServiceLabel = (
    serviceName: string,
    optionName: string,
    startDate: string,
  ) => {
    if (
      mapToArray<SlotService>(serviceData.slotServices).filter(
        (s) => s.service.name === serviceName,
      ).length === 1
    ) {
      return t('app.booking-details.price-option.dropdown-label', {
        serviceName,
        optionName,
      });
    } else {
      return t('app.booking-details.price-option.dropdown-label-at-time', {
        serviceName,
        optionName,
        serviceTime: dateAndTimeFormatter.formatTime(startDate),
      });
    }
  };

  return (
    <>
      {servicesWithDynamicPriceWithCustomOptions.map((slotService) =>
        slotService.dynamicPriceInfo?.customOptions?.map(
          (customOption: CustomOption) => {
            let label = customOption.name;
            if (
              experiments.enabled(
                ExperimentsConsts.FormPaymentOptionNewDropdown,
              )
            ) {
              label = getServiceLabel(
                slotService.service.name,
                customOption.name,
                slotService.nestedSlot.startDate,
              );
            }
            return (
              <PriceOptionDropdown
                key={getServiceSlotIdentifier(slotService.nestedSlot)}
                componentKey={getServiceSlotIdentifier(slotService.nestedSlot)}
                showPrice={
                  slotService.selectedPaymentOption.id ===
                  ReservedPaymentOptionIds.SingleSession
                }
                options={customOption.options}
                onDropdownClose={(
                  priceOptionData: PriceOptionNumberOfParticipants[],
                ) => {
                  onSelectedVariants(
                    priceOptionData,
                    customOption.optionId,
                    getServiceSlotIdentifier(slotService.nestedSlot),
                  );
                  clearErrorByTypes([DynamicPriceErrorType]);
                }}
                maxParticipants={getMaxNumberOfParticipants(
                  serviceData,
                  getServiceSlotIdentifier(slotService.nestedSlot),
                )}
                disabled={status === FormStatus.PROCESSING_PAYMENT_DETAILS}
                label={label}
                error={!!firstDynamicPriceError}
                errorMessage={firstDynamicPriceError?.params?.message}
              />
            );
          },
        ),
      )}
    </>
  );
};

export default PriceOptionDropdownContainer;
